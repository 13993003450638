import React,{useEffect, useState} from "react";
import {Helmet} from 'react-helmet';
import Style from "../styles/About.module.css";
import dinoImg from '../imgs/About/dino.jpg';
import img1 from '../imgs/About/family.jpg'
import img2 from '../imgs/About/friend.jpg'; 
import img3 from '../imgs/About/workers.jpg';
const About = () =>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const [activeIndex, setActiveIndex] = useState(0);

      useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndex((prevIndex) =>
            prevIndex === 2 ? 0 : prevIndex + 1
          );
        }, 3000);
        return () => clearInterval(interval);
      }, []);
     
    return(
        <div>
            <Helmet>
            <title>About Us - Dino Constructions</title>
            <meta name="description" content="Learn more about Dino Constructions and our team of experts in construction and renovation." />
            <meta property="og:title" content="About Us - Dino Constructions" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://dinoconstructions.com/AboutUs" />
            <link rel="canonical" href="https://dinoconstructions.com/AboutUs"/>
            <meta property="og:description" content="Learn more about Dino Constructions and our team of experts in construction and renovation." />
      </Helmet>
            <div className={Style.Content1}>
                <div className={Style.Content1Intro}>
                    <h1>About US</h1>
                    <div className={Style.h1BrkLine}></div>
                </div>
            </div>
            <div className={Style.Content2}>
                <div className={Style.Content2PartA}>
                    <div className={Style.PartAInfo}>
                        <h2>FAMILY OWNED BUSINESS</h2>
                        <p>Meet Secundino, the proud owner of Dino Construction - a family owned business that has been thriving since 2010. With a passion for construction and an eye for detail, 
                            Secundino has built a reputation for excellence in various areas including concrete, roofs, paint, tree removal, and landscaping. His business has become a go-to for residents 
                            in the local community and beyond, thanks to his commitment to providing high-quality services at affordable prices.</p>
                     </div>
                </div>
                <div className={Style.Content2PartB}>
                    <img src={dinoImg} alt="Owner of Dino Construction"></img>
                </div>
            </div>
            <div className={Style.Content3}>
                <div className={Style.Content3PartA}>
                    <h2>Our Vision</h2>
                    <p>Our vision at Dino Construction is to establish ourselves as the leading construction company, highly regarded for our unmatched quality of work, meticulous attention to detail, and 
                        unwavering dedication to ensuring customer satisfaction. Moreover, we aim to make a positive impact on the growth and development of our community by supporting local businesses 
                        whenever possible. We firmly believe that by working collaboratively, we can foster a more prosperous and thriving community that benefits everyone.</p>
                </div>
            </div>
            <div className={Style.Content4}>
                <div className={Style.Content4PartA}>
                <img
                    className={`${Style.img1} ${activeIndex === 0 && Style.active}`}
                    src={img1}
                    alt=""
                    />
                    <img
                    className={`${Style.img2} ${activeIndex === 1 && Style.active}`}
                    src={img2}
                    alt=""
                    />
                    <img
                    className={`${Style.img3} ${activeIndex === 2 && Style.active}`}
                    src={img3}
                    alt=""
                    />
            </div>
                <div className={Style.Content4PartB}>
                    <div className={Style.PartBInfo}>
                        <h2>Building Strong Relationships</h2>
                        <p>At Dino Construction, we believe that building strong relationships with our clients is the foundation of any successful project. That's why we make it a priority to truly understand your vision and goals for your project, so we can work together to make it a reality. Our team of experts is committed to providing the highest level of quality, professionalism, and communication throughout every stage of the construction process. From the initial consultation to the final walkthrough, we will work closely with you to ensure that your project is completed on time, within budget, and to your complete satisfaction. So if you're looking for a construction company that values strong relationships, quality workmanship, and exceptional customer service, look no further than Dino Construction. We would be honored to work with you on your next project.
</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;