import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';
import Style from '../styles/Services.module.css';
import service1 from '../imgs/roofingServices/metalService.jpg';
import service2 from '../imgs/roofingServices/shinglesService.jpg';
import service3 from '../imgs/roofingServices/repairServices.jpg';

import before1 from '../imgs/roofingServices/before1.jpg';
import after1 from '../imgs/roofingServices/after1.jpg';
import before2 from '../imgs/roofingServices/after2.jpg';
import after2 from '../imgs/roofingServices/before2.jpg';
import before3 from '../imgs/roofingServices/before3.jpg';
import after3 from '../imgs/roofingServices/after3.jpg';
import before4 from '../imgs/roofingServices/before4.jpg';
import after4 from '../imgs/roofingServices/after4.jpg';
import ImageBlock from "../components/ImageBlock";

const Roofing = ()=>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <Helmet>
                <title>Roofing Services - Dino Constructions</title>
                <meta name="description" content="Get high-quality roofing services from Dino Constructions. Our team of experts can handle any roofing project, from repair to installation." />
                <meta property="og:title" content="Roofing Services - Dino Constructions" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dinoconstructions.com/Roofing" />
                <link rel="canonical" href="https://dinoconstructions.com/Roofing"/>
                <meta property="og:description" content="Get high-quality roofing services from Dino Constructions. Our team of experts can handle any roofing project, from repair to installation." />
            </Helmet>
            <div className={`${Style.Content1} ${Style.banner4}`}>
                <div className={Style.Content1A}>
                    <h1>Roofing Services</h1>
                    <Link to='/Gallery'> <button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#A04B43"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>View Our Work</button></Link>
                    <Link to='/ContactUs'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#A04B43"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>
                   Request A Quote</button></Link> 
                </div>
            </div>
            <div className={Style.Content2} style={{backgroundColor:"#A04B43"}} >
                <div className={Style.Content2Intro}>
                    <p>Transform your home with our exceptional roofing services. From repairs to complete roof replacement, we use only the finest quality materials and techniques to create a durable, low-maintenance, and beautifully designed roof that fits your unique needs and style. </p>
                </div>
            </div>
            <div className={Style.Content3}>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service1} alt="Metal Roofing"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#A04B43'}}>Beauty and Durability for Your Home</h3>
                            <p>Upgrade your home with the ultimate combination of beauty and durability - metal roofing. Our metal roofing services provide unmatched protection against the elements while enhancing the overall aesthetic of your home. With a range of colors and finishes to choose 
                                from, we'll work with you to create a look that perfectly complements your personal style and the architecture of your home. Plus, our expert installation ensures a long-lasting, low-maintenance solution for years to come. Don't settle for ordinary roofing - 
                                choose the exceptional quality and style of metal roofing. </p>
                        </div>  
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service2} alt="Shingles Roof"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#A04B43'}}>Top Off Your Home with Superior Shingles</h3>
                            <p>Boost your home's curb appeal and safeguard it from the elements with our shingle services. Our top-quality shingles come in a wide variety of colors and styles, so you can achieve the perfect look for your home. Our skilled team of professionals will provide expert 
                                installation to ensure long-lasting, durable protection for your roof. Plus, our shingles are designed to require minimal maintenance, so you can enjoy a stunning and worry-free roof for years to come. Don't settle for anything less than the best for your home. </p>
                        </div> 
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service3} alt="Roof Repair"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#A04B43'}}>Repairs and Replacements for Your Roofing Needs</h3>
                            <p>Protect your home and keep your roof in top condition with our expert repair and replacement services. Our skilled technicians will thoroughly inspect your roof to determine the extent of the damage and recommend the best course of action to restore your roof's integrity.
                                 Whether you need a few shingles replaced or a full roof replacement, we use only the highest quality materials and techniques to ensure a durable and long-lasting solution. With our prompt and professional service, you can trust that your roof is in good hands. Don't wait 
                                 until it's too late to address your roofing issues.</p>
                        </div>  
                    </div>  
                </div>
            </div>
            <div className={Style.Content4} style={{backgroundColor:"#A04B43"}} >
                <div className={Style.Content4Intro}>
                    <h2>Before / After</h2>
                    <p>Using our slider tool, you can witness the transformative power of our Roofing services and see the remarkable difference we can make to your property. Whether you're looking to upgrade your outdoor living area or enhance the curb appeal of your home, our team of experts can deliver exceptional results that exceed your expectations. </p>
                </div>
                <div className={Style.beforeAfterGrid}>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before1} afterImage={after1} />
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before2} afterImage={after2}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before3} afterImage={after3}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before4} afterImage={after4}/>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Roofing;