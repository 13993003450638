import React,{useEffect} from "react";
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Style from '../styles/Services.module.css';
import service1 from '../imgs/deckServices/deckService.jpg';
import service2 from '../imgs/deckServices/fenceService.jpg';
import service3 from '../imgs/paintServices/stainDeck.jpg';

import before1 from '../imgs/deckServices/before1.jpg';
import after1 from '../imgs/deckServices/after1.jpg';
import before2 from '../imgs/deckServices/before2.jpg';
import after2 from '../imgs/deckServices/after2.jpg';
import before3 from '../imgs/deckServices/before3.jpg';
import after3 from '../imgs/deckServices/after3.jpg';
import before4 from '../imgs/deckServices/before4.jpg';
import after4 from '../imgs/deckServices/deckService.jpg';
import ImageBlock from "../components/ImageBlock";

const ServicePaint = ()=>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <Helmet>
                <title>Decks / Fences - Dino Constructions</title>
                <meta name="description" content="Dino Constructions specializes in building custom decks and fences. Get a free quote today!" />
                <meta property="og:title" content="Decks / Fences - Dino Constructions" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dinoconstructions.com/Porches-Decks-Fences"/>
                <link rel="canonical" href="https://dinoconstructions.com/Porches-Decks-Fences"/>
                <meta property="og:description" content="Dino Constructions specializes in building custom decks and fences. Get a free quote today!" />
            </Helmet>
            <div className={`${Style.Content1} ${Style.banner3}`}>
                <div className={Style.Content1A}>
                    <h1>Decks / Fences Services</h1>
                    <Link to='/Gallery'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#AEA96F"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>View Our Work</button></Link>
                    <Link to='/ContactUs'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#AEA96F"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>
                   Request A Quote</button></Link> 
                </div>
            </div>
            <div className={Style.Content2} style={{backgroundColor:"#AEA66F"}} >
                <div className={Style.Content2Intro}>
                    <p>We use top-quality materials and techniques to create a durable, low-maintenance, and beautifully designed deck that fits your unique needs and lifestyle.
                    We take into account the architecture and landscaping of your property to ensure the perfect fit for your outdoor space.</p>
                </div>
            </div>
            <div className={Style.Content3}>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service1} alt="Two Floor Deck"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#AEA66F'}}>Crafting Your Perfect Outdoor Space </h3>
                            <p>Elevate your outdoor living with our expert deck services. Our skilled craftsmen use only the finest materials and techniques to design and build 
                                custom decks that are both durable and stylish. We work closely with you to create a deck that complements your home's architecture and meets your 
                                specific needs, resulting in a beautiful and functional outdoor space that you can enjoy for years to come.  </p>
                        </div>  
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service2} alt="Wood Fence"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#AEA66F'}}>Secure Your Property with our Fence Services</h3>
                            <p>Looking for a way to enhance the security and privacy of your property? Our expert wooden fence installation services are the perfect solution! We use only the highest quality wood materials and techniques to provide you 
                                with a fence that is both durable and stylish. With a variety of styles and designs to choose from, we can create a personalized fence installation plan that fits your needs and budget. Contact us today to schedule your 
                                consultation and start enjoying the benefits of a beautiful and functional wooden fence. </p>
                        </div> 
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service3} alt="Deck Coating"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#AEA66F'}}>Protective Coatings for Exterior Wood Surfaces</h3>
                            <p>Our deck and fence staining service is designed to protect and enhance the natural beauty of your outdoor spaces. We use high-quality stains and sealers to ensure that your deck 
                                and fence are protected from the elements, while also giving them a fresh, new look. Our team of experts will work with you to choose the perfect color and finish to complement your 
                                home and landscape. Let us help you transform your outdoor living area into a beautiful, inviting space that you can enjoy for years to come.</p>
                        </div>  
                    </div>  
                </div>
            </div>
            <div className={Style.Content4} style={{backgroundColor:"#AEA66F"}} >
                <div className={Style.Content4Intro}>
                    <h2>Before / After</h2>
                    <p>Using our slider tool, you can witness the transformative power of our Decks/Fences services and see the remarkable difference we can make to your property. Whether you're looking to upgrade your outdoor living area or enhance the curb appeal of your home, our team of experts can deliver exceptional results that exceed your expectations. </p>
                </div>
                <div className={Style.beforeAfterGrid}>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before1} afterImage={after1} />
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before2} afterImage={after2}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before3} afterImage={after3}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before4} afterImage={after4}/>
                </div>
                </div>
            </div>
        </div>
    );
}

export default ServicePaint;