import React from "react";
import Style from '../styles/Footer.module.css';
import logo from '../imgs/logoImg.png';
import award from '../imgs/awardLogo.png';
import faceIcon from '../imgs/homePage/facebookIcon.svg';
import instaIcon from '../imgs/homePage/instaIcon.svg';
import tiktokIcon from '../imgs/homePage/tiktokIcon.svg';

const Footer= () =>{
    return ( 
        <div className={Style.Footer}>
            <div className={Style.FooterContent}>
                <div className={Style.PartA}>
                    <div className={Style.LogoFooter}>
                        <img className={Style.bannerLogo} src={logo} alt="Company Logo"></img>
                        <img className={Style.awardLogo} src={award} alt="NextDoor 2023 Award"></img>
                    </div>
                    <p>Bringing your vision to life with our commitment to quality construction - building a better community for all</p>
                </div>
                <div className={Style.VerticalLine}></div>
                <div className={Style.PartB}>
                    <div className={Style.ContactContent}>
                        <h3>Contact Us</h3>
                        <p><strong>P:</strong><a href="tel:828-702-8101"> (828) 702-8101</a></p>
                        <p><strong>P:</strong><a href="tel:828-206-4457"> (828) 206-4457</a></p>
                        <p><strong>E:</strong><a href="mailto:Dino@DinoConstructions.com"> Dino@DinoConstructions.com</a></p>
                    </div>
                    <div className={Style.FollowContent}>
                        <h3>Follow Us</h3>
                        <div className={Style.IconContent}>
                            <div><a href="https://www.facebook.com/profile.php?id=100054311676877"><img src={faceIcon} alt="Facebook"/></a></div>
                            <div><a href="https://www.instagram.com/dino_castillo/"><img src={instaIcon} alt="Instagram"/></a></div>
                            <div><a href="https://www.tiktok.com/@dinocastill"><img src={tiktokIcon} alt="TikTok"/></a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Style.HorizontalLn}></div>
            <div className={Style.Copyright}>
                <p>Copyright © 2023 Dino Construction - All rights reserved.</p>
            </div>
        </div>
    );
};

export default Footer;