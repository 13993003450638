/// Import Imgs For Concrete
import concrete1 from '../imgs/Gallery/concrete/img1.jpg';
import concrete2 from '../imgs/Gallery/concrete/img2.jpg';
import concrete3 from '../imgs/Gallery/concrete/img3.jpg';
import concrete4 from '../imgs/Gallery/concrete/img4.jpg';
import concrete5 from '../imgs/Gallery/concrete/img5.jpg';
import concrete6 from '../imgs/Gallery/concrete/img6.jpg';
import concrete7 from '../imgs/Gallery/concrete/img7.jpg';
import concrete8 from '../imgs/Gallery/concrete/img8.jpg';
import concrete9 from '../imgs/Gallery/concrete/img9.jpg';
import concrete10 from '../imgs/Gallery/concrete/img10.jpg';
import concrete11 from '../imgs/Gallery/concrete/img11.jpg';
import concrete12 from '../imgs/Gallery/concrete/img12.jpg';
import concrete13 from '../imgs/Gallery/concrete/img13.jpg';

/// import Imgs for Paint
import paint1 from '../imgs/Gallery/paint/img1.jpg';
import paint2 from '../imgs/Gallery/paint/img2.jpg';
import paint3 from '../imgs/Gallery/paint/img3.jpg';
import paint4 from '../imgs/Gallery/paint/img4.jpg';
import paint5 from '../imgs/Gallery/paint/img5.jpg';
import paint6 from '../imgs/Gallery/paint/img6.jpg';
import paint7 from '../imgs/Gallery/paint/img7.jpg';
import paint8 from '../imgs/Gallery/paint/img8.jpg';
import paint9 from '../imgs/Gallery/paint/img9.jpg';

/// import Imgs For Roof
import roof1 from '../imgs/Gallery/roof/img1.jpg';
import roof2 from '../imgs/Gallery/roof/img2.jpg';
import roof3 from '../imgs/Gallery/roof/img3.jpg';
import roof4 from '../imgs/Gallery/roof/img4.jpg';
import roof5 from '../imgs/Gallery/roof/img5.jpg';
import roof6 from '../imgs/Gallery/roof/img6.jpg';
import roof7 from '../imgs/Gallery/roof/img7.jpg';
import roof8 from '../imgs/Gallery/roof/img8.jpg';
import roof9 from '../imgs/Gallery/roof/img9.jpg';
import roof10 from '../imgs/Gallery/roof/img10.jpg';
import roof11 from '../imgs/Gallery/roof/img11.jpg';
import roof12 from '../imgs/Gallery/roof/img12.jpg';
import roof13 from '../imgs/Gallery/roof/img13.jpg';

/// import Imgs for Deck
import deck1 from '../imgs/Gallery/deck/img1.jpg';
import deck2 from '../imgs/Gallery/deck/img2.jpg';
import deck3 from '../imgs/Gallery/deck/img3.jpg';
import deck4 from '../imgs/Gallery/deck/img4.jpg';
import deck5 from '../imgs/Gallery/deck/img5.jpg';
import deck6 from '../imgs/Gallery/deck/img6.jpg';
import deck7 from '../imgs/Gallery/deck/img7.jpg';
import deck8 from '../imgs/Gallery/deck/img8.jpg';
import deck9 from '../imgs/Gallery/deck/img9.jpg';
import deck10 from '../imgs/Gallery/deck/img10.jpg';
import deck11 from '../imgs/Gallery/deck/img11.jpg';
import deck12 from '../imgs/Gallery/deck/img12.jpg';
import deck13 from '../imgs/Gallery/deck/img13.jpg';
import deck14 from '../imgs/Gallery/deck/img14.jpg';
import deck15 from '../imgs/Gallery/deck/img15.jpg';
import deck16 from '../imgs/Gallery/deck/img16.jpg';
import deck17 from '../imgs/Gallery/deck/img17.jpg';
import deck18 from '../imgs/Gallery/deck/img18.jpg';
import deck19 from '../imgs/Gallery/deck/img19.jpg';
import deck20 from '../imgs/Gallery/deck/img20.jpg';
import deck21 from '../imgs/Gallery/deck/img21.jpg';

///import Img for Stone
import stone1 from '../imgs/Gallery/stone/img1.jpg';
import stone2 from '../imgs/Gallery/stone/img2.jpg';
import stone3 from '../imgs/Gallery/stone/img3.jpg';
import stone4 from '../imgs/Gallery/stone/img4.jpg';
import stone5 from '../imgs/Gallery/stone/img5.jpg';
import stone6 from '../imgs/Gallery/stone/img6.jpg';
import stone7 from '../imgs/Gallery/stone/img7.jpg';
import stone8 from '../imgs/Gallery/stone/img8.jpg';
import stone9 from '../imgs/Gallery/stone/img9.jpg';
import stone10 from '../imgs/Gallery/stone/img10.jpg';
import stone11 from '../imgs/Gallery/stone/img11.jpg';

///import IMg for block
import block1 from '../imgs/Gallery/block/img1.jpg';
import block2 from '../imgs/Gallery/block/img2.jpg';
import block3 from '../imgs/Gallery/block/img3.jpg';
import block4 from '../imgs/Gallery/block/img4.jpg';
import block5 from '../imgs/Gallery/block/img5.jpg';
import block6 from '../imgs/Gallery/block/img6.jpg';
import block7 from '../imgs/Gallery/block/img7.jpg';
import block8 from '../imgs/Gallery/block/img8.jpg';


const images = {
  paint: [
      paint1,
      paint2,
      paint3,
      paint4,
      paint5,
      paint6,
      paint7,
      paint8,
      paint9
  ],
  
  concrete: [
      concrete1,    
      concrete2,
      concrete3,
      concrete4,
      concrete5,
      concrete6,
      concrete7,
      concrete8,
      concrete9,
      concrete10,
      concrete11,
      concrete12,
      concrete13
    ],

  roof:[
      roof1,
      roof2,
      roof3,
      roof4,
      roof5,
      roof6,
      roof7,
      roof8,
      roof9,
      roof10,
      roof11,
      roof12,
      roof13
  ],

  deck:[
    deck1,
    deck2,
    deck3,
    deck4,
    deck5,
    deck6,
    deck7,
    deck8,
    deck9,
    deck10,
    deck11,
    deck12,
    deck13,
    deck14,
    deck15,
    deck16,
    deck17,
    deck18,
    deck19,
    deck20,
    deck21
  ],

  stone:[
     stone1,
     stone2,
     stone3,
     stone4,
     stone5,
     stone6,
     stone7,
     stone8 ,
     stone9,
     stone10,
     stone11
  ],

  block:[
    block1,
    block2,
    block3,
    block4,
    block5,
    block6,
    block7,
    block8
  ],
};

export default images;