import React,{useEffect} from "react";
import {Helmet} from 'react-helmet';
import Style from '../styles/Home.module.css';
import { Link } from "react-router-dom";

import service1 from '../imgs/homePage/Content1/service1.jpg';
import service2 from '../imgs/homePage/Content1/service2.jpg';
import service3 from '../imgs/homePage/Content1/service3.jpg';
import service4 from '../imgs/homePage/Content1/service4.jpg';
import service5 from '../imgs/homePage/Content1/service5.jpg';
import service6 from '../imgs/homePage/Content1/service6.jpg';

const HomePage = () =>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <Helmet>
                <title>Dino Constructions - Your Trusted Home Renovation Experts</title>
                <meta name="description" content="Dino Constructions is a team of experts in home renovation and construction. We specialize in high-quality workmanship and exceptional customer service." />
                <meta property="og:title" content="Dino Constructions - Your Trusted Home Renovation Experts" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dinoconstructions.com" />
                <link rel="canonical" href="https://dinoconstructions.com/"/>
                <meta property="og:description" content="Dino Constructions is a team of experts in home renovation and construction. We specialize in high-quality workmanship and exceptional customer service." />
            </Helmet>
        <div className={Style.Content1}>
            <div className={Style.introTitle}>
                <h1><span className={Style.h1ToRed} >Your top choice for quality</span><br></br> construction services</h1>
                <p>At Dino Construction, we pride ourselves on delivering exceptional construction services in concrete, decking, roofing, and more, all carried out by our team of skilled professionals who are committed to making your project a success.
               <br></br>Contact us today to schedule a consultation and let us bring your vision to life.</p>
            </div>
            <div className={Style.buttonContainer}>
                <Link to='/ContactUs'><button className={Style.Content1Btns}>Request A Quote</button></Link>
                <Link to='/Gallery'><button className={Style.Content1Btns}>View Projects</button></Link>
            </div>
        </div>
        <div className={Style.Content2}>
            <h2>All of Your Construction Needs in one place</h2>
            <p>At Dino Construction, we take pride in offering a wide range of high-quality services to our customers, including concrete work, decking, roofing, and 
                more. Our experienced professionals are dedicated to delivering exceptional results, no matter the size or complexity of the project.</p>
        </div>
        <div className={Style.Content3}>
            <div className={Style.serviceA}>
                <div className={Style.serviceB}><img className={Style.serviceAImg}src={service1} alt='Concrete Driveway'/></div>
                <div className={Style.serviceAContent}>
                    <h3>Comprehensive Concrete Solutions</h3>
                    <p>From driveways to slabs and sidewalks, our concrete services include repair and maintenance to ensure your project is always in top shape. #DinoConstruction</p>
                    <Link to='/Concrete'><button className={Style.Content3LearnBtn}>Learn More</button></Link>
                </div>
           </div>
        </div>
        <div className={Style.Content4}>
            <div className={Style.serviceB}>
                <div className={Style.serviceBContent}>
                    <h3>Professional Painting Services</h3>
                    <p>From interior to exterior painting, our services also include repair and maintenance to keep your property looking its best. Trust the experts at #DinoConstruction for all your painting needs</p>
                    <Link to='/Paint'><button className={Style.Content3LearnBtn}>Learn More</button></Link>
                </div>
                <div className={Style.imgContainer} ><img className={Style.serviceBImg}src={service2} alt='House Paint'/></div>
                
            </div>
        </div>
        <div className={Style.Content3}>
            <div className={Style.serviceA}>
            <div className={Style.serviceB} ><img className={Style.serviceAImg}src={service3} alt='Deck '/></div>
                <div className={Style.serviceAContent}>
                    <h3>Custom Porches and Decks</h3>
                    <p>Create your dream outdoor space with custom porches and decks from Dino Construction. We offer design, installation, and repair services. Contact us today to learn more!</p>
                    <Link to='/Porches-Decks-Fences'><button className={Style.Content3LearnBtn}>Learn More</button></Link>
                </div>
           </div>
        </div>
        <div className={Style.Content4}>
            <div className={Style.serviceB}>
                <div className={Style.serviceBContent}>
                    <h3>Roofing Services </h3>
                    <p>Protect your property with expert roofing services from Dino Construction. We offer metal roofing, shingle installation, repair, and more.</p>
                    <Link to='/Roofing'><button className={Style.Content3LearnBtn}>Learn More</button></Link>
                </div>
            <div className={Style.imgContainer}  ><img className={Style.serviceBImg}src={service4} alt='Shingle Roof'/></div>
            </div>
        </div>
        <div className={Style.Content3}>
            <div className={Style.serviceA}>
            <div className={Style.serviceB} ><img className={Style.serviceAImg}src={service5} alt='Stone Patio'/></div>
                <div className={Style.serviceAContent}>
                    <h3>Custom Stone Patio Design and Installation</h3>
                    <p>Elevate your outdoor living space with custom stone patio design and installation from Dino Construction. Learn more about our services today!</p>
                    <Link to='/StonePatio'><button className={Style.Content3LearnBtn}>Learn More</button></Link>
                </div>
           </div>
        </div>
        <div className={Style.Content4}>
            <div className={Style.serviceB}>
                <div className={Style.serviceBContent}>
                    <h3>Block Foundation Services</h3>
                    <p>Ensure the safety and longevity of your property with expert block foundation services from Dino Construction. Learn more today!</p>
                    <Link to='/BlockFoundation'><button className={Style.Content3LearnBtn}>Learn More</button></Link>
                </div>
            <div className={Style.imgContainer}  ><img className={Style.serviceBImg}src={service6} alt='Block Foundation House'/></div>
            </div>
        </div>
        <div className={Style.Content5}>
            <div className={Style.locationInfo}>
                <h2>Our Location</h2>
                <div className={Style.brLine}></div>
                <p>From Downtown to the Mountains <br></br> Dino Construction Builds Your Vision Anywhere in Asheville and Beyond</p>
                <Link to='/ContactUs'><button className={Style.Content5Btns}>Request A Quote</button></Link>
            </div>
            <div className={Style.map}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d415071.5387380142!2d-82.669582!3d35.633978!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6031325ca1b2f97b%3A0xbd3fef53f0beb782!2sDino%20Construction!5e0!3m2!1sen!2sus!4v1688691122782!5m2!1sen!2sus"
                    title="Map"
                    width="99.5%"
                    height="99%"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
     </div>
    );
}

export default HomePage;