import React, { useState } from 'react';
import {Helmet} from 'react-helmet';
import Style from '../styles/Gallery.module.css';
import images from '../components/PreloadImages'


const categories = [
  {
    id: 1,
    name: 'Paint',
    images: [
    images.paint[0],
    images.paint[1],
    images.paint[2],
    images.paint[3],
    images.paint[4],
    images.paint[5],
    images.paint[6],
    images.paint[7],
    images.paint[8],


    ],
  },
  {
    id: 2,
    name: 'Concrete',
    images: [
     images.concrete[0],
     images.concrete[1],
     images.concrete[2],
     images.concrete[3],
     images.concrete[4],
     images.concrete[5],
     images.concrete[6],
     images.concrete[7],
     images.concrete[8],
     images.concrete[9],
     images.concrete[10],
     images.concrete[11],
     images.concrete[12],
    ]
  },
  {
    id: 3,
    name: 'Roof',
    images: [
    images.roof[0],
    images.roof[1],
    images.roof[2],
    images.roof[3],
    images.roof[4],
    images.roof[5],
    images.roof[6],
    images.roof[7],
    images.roof[8],
    images.roof[9],
    images.roof[10],
    images.roof[11],
    images.roof[12],
    ],
  },
  {
    id: 4,
    name: 'Deck/Fences',
    images: [
     images.deck[0],
     images.deck[1],
     images.deck[2],
     images.deck[3],
     images.deck[4],
     images.deck[5],
     images.deck[6],
     images.deck[7],
     images.deck[8],
     images.deck[9],
     images.deck[10],
     images.deck[11],
     images.deck[12],
     images.deck[13],
     images.deck[14],
     images.deck[15], 
     images.deck[16],
     images.deck[17],
     images.deck[18],
     images.deck[19],
     images.deck[20],
    ],
  },
  {
    id: 5, 
    name: 'Stone Patio',
    images: [
     images.stone[0],
     images.stone[1],
     images.stone[2],
     images.stone[3],
     images.stone[4],
     images.stone[5],
     images.stone[6],
     images.stone[7],
     images.stone[8],
     images.stone[9],
     images.stone[10],
    ],
  },
  {
    id: 6,
    name: 'Block Foundation',
    images: [
      images.block[0],
      images.block[1],
      images.block[2],
      images.block[3],
      images.block[4],
      images.block[5],
      images.block[6],
      images.block[7],
    ],
  },
];
function Gallery() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const filteredImages = selectedCategory === null
  ? categories.reduce((allImages, category) => allImages.concat(category.images), [])
  : categories.find(category => category.name === selectedCategory)?.images || [];

  function handleImageClick(image) {
    setSelectedImage(image);
  }

  return (
    <div>
      <Helmet>
        <title>Gallery - Dino Constructions</title>
        <meta name="description" content="Explore our impressive portfolio at Dino Constructions gallery and see our expert craftsmanship in action." />
        <meta property="og:title" content="Gallery - Dino Constructions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://dinoconstructions.com/Gallery" />
        <link rel="canonical" href="https://dinoconstructions.com/Gallery" />
        <meta property="og:description" content="Explore our impressive portfolio at Dino Constructions gallery and see our expert craftsmanship in action." />
      </Helmet>

      <div>
        <div className={Style.Content1}>
          <div className={Style.Intro}>
            <h1>Explore Our Impressive Portfolio: The Dino Constructions Gallery</h1>
          </div>
        </div>
        <div className={Style.navigationMenu}>
          <ul>
            <li className={!selectedCategory ? Style.active : ''} onClick={() => setSelectedCategory(null)}>All</li>
            {categories.map(category => (
              <li key={category.id} className={selectedCategory === category.name ? Style.active : ''} onClick={() => setSelectedCategory(category.name)}>{category.name}</li>
            ))}
          </ul>
        </div>
        <div className={Style.ImgGrid}>
          {filteredImages.map((image, index) => (
            <img key={index} src={image} alt={`Project ${index}`} onClick={() => handleImageClick(image)} />
          ))}
        </div>
      </div>
      {selectedImage && (
        <div className={Style.enlargedImgContainer} onClick={() => setSelectedImage(null)}> 
        <img className={Style.enlargedImg} src={selectedImage} alt={`Enlarged Project`} />
        </div>
      )}
    </div>
  );
}

export default Gallery;