import React, {useState, useEffect,useRef} from "react";
import axios from "axios";
import {Helmet} from 'react-helmet';
import Style from '../styles/Contact.module.css';

const Contact = () => {
    const formRef = useRef(null);
        useEffect(() => {
            window.scrollTo(0, 0);
          }, []);
          const [photos, setPhotos] = useState([null, null, null, null]);
          const [isLoading, setIsLoading] = useState(false);
          const [isSubmitted,setIsSubmitted] = useState(false);

          const [firstName, setFirstName] = useState("");
          const [lastName, setLastName] = useState("");
          const [email, setEmail] = useState("");
          const [phone, setPhone] = useState("");
          const [serviceType, setServiceType] = useState("");
          const [additionalInfo, setAdditionalInfo] = useState("");
          const [address, setAddress] = useState("");
          const [city, setCity] = useState("");
          const [state, setState] = useState("");
          const [zipcode,setZipcode] = useState("");

          
          
          const handleSubmit = async (event) => {
            event.preventDefault();
            setIsLoading(true);
          
            const formData = new FormData();
                formData.append('firstName', firstName);
                formData.append('lastName',lastName);
                formData.append('email', email);
                formData.append('phone', phone);
                formData.append('serviceType', serviceType);
                formData.append('additionalInfo',additionalInfo);
                formData.append('address',address);
                formData.append('city',city);
                formData.append('state',state);
                formData.append('zipcode',zipcode);

                const nonNullPhotos = photos.filter(photo => photo !== null);
                // add non-null images to form data
              nonNullPhotos.forEach((photo, index) => {
                 formData.append(`photo${index}`, photo);
              });
            console.log('Form data:', Object.fromEntries(formData.entries()));
           
            try {
                console.log('Sending request...');
                const response = await axios.post('https://dinoconstructions.com:3000/api/form', formData);
                console.log('Response:', response);
            
                const data = response.data;
                console.log('Data:', data);
                setIsLoading(false);
                setIsSubmitted(true);
              } catch (error) {
                console.log('Error:', error);
                setIsLoading(false);
                alert('Sorry, there was an error sending your message. Please try again later.');
              }
            
              setFirstName("");
              setLastName("");
              setEmail("");
              setPhone("");
              setServiceType("");
              setAdditionalInfo("");
              setAddress("");
              setCity("");
              setState("");
              setZipcode("");
            setPhotos([]);
          };
          

const handlePhotoUpload = (event, index) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = () => {
      setPhotos((prevPhotos) => {
        const newPhotos = [...prevPhotos];
        newPhotos[index] = reader.result;
        return newPhotos;
      });
    };
  
    reader.readAsDataURL(file);
  };

  const handlePhotoDelete = (index) => {
    setPhotos((prevPhotos) => {
      const newPhotos = [...prevPhotos];
      newPhotos[index] = null;
      return newPhotos;
    });
  };
    return(
        <div>
            <Helmet>
                <title>Contact Us - Dino Constructions</title>
                <meta name="description" content="Contact Dino Constructions for any inquiries, questions or comments about our services." />
                <meta property="og:title" content="Contact Us - Dino Constructions" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dinoconstructions.com/ContactUs" />
                <link rel="canonical" href="https://dinoconstructions.com/ContactUs"/>
                <meta property="og:description" content="Contact Dino Constructions for any inquiries, questions or comments about our services." />
            </Helmet>
            <div className={Style.Content1}>
                <div className={Style.Content1Intro}>
                    <h1>Contact US</h1>
                    <p>At Dino Construction, we are always ready to discuss your construction needs and answer any questions you may have.
                    Please don't hesitate to reach out to us today to schedule a consultation or to learn more about our services. We look forward to hearing from you!</p>
                </div>
            </div>
            <div className={Style.Content2}>
                <div className={Style.PartA}>
                    <div className={Style.PartAHeading}>
                        <h2>Our Location</h2>
                        <div className={Style.brLine}></div>
                        <p>From Downtown to the Mountains<br></br> Dino Construction Builds Your Vision Anywhere in Asheville and Beyond</p>
                    </div>
                    <div className={Style.PartAHours}>
                        <h3>Open Monday-Friday</h3>
                        <p>7:00Am-6:00PM</p>
                    </div>
                    <div className={Style.PartAButtons}>
                        <a href="tel:8287028101">Call Us</a>
                        <a href="mailto:Dino@DinoConstructions.com">Email Us</a>
                    </div>
                </div>
                <div className={Style.PartB}>
                    
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d415071.5387380142!2d-82.669582!3d35.633978!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6031325ca1b2f97b%3A0xbd3fef53f0beb782!2sDino%20Construction!5e0!3m2!1sen!2sus!4v1688691122782!5m2!1sen!2sus"
                    title="Map"
                    width="99.5%"
                    height="99%"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                 
                </div>
            </div>
            <div className={Style.Content3}> 
            <form id="form" onSubmit={handleSubmit} ref={formRef} encType="multipart/form-data">
                    <div className={Style.CustomerDetails}>
                        <h2>Get An Estimate</h2>
                        <div className={Style.CustomerContainer}>
                            <div className={Style.ContainerFirstName}>
                                <label htmlFor="firstName">First Name:</label>
                                <input 
                                type="text" 
                                id="firstName" 
                                name="firstName"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}
                                required 
                                />
                            </div>
                            <div className={Style.ContainerLastName}>
                                <label htmlFor="lastName">Last Name:</label>
                                <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}
                                required
                                />
                            </div>
                            <div className={Style.ContainerEmail}>
                                <label htmlFor="email">Email Address:</label>
                                <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                                />
                            </div>
                            <div className={Style.ContainerPhone}>
                                <label htmlFor="phone">Phone:</label>
                                <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                                required
                                />
                            </div>
                            <div className={Style.ContainerService}>
                                <label htmlFor="serviceType">Service Type:</label>
                                <select
                                id="serviceType"
                                name="serviceType"
                                value={serviceType}
                                onChange={(event) => setServiceType(event.target.value)}
                                required
                                >
                                <option value="">Choose a service type</option>
                                <option value="Concrete">Concrete</option>
                                <option value="Paint">Paint</option>
                                <option value="Decks/Porches/Fences">Decks/Porches/Fences</option>
                                <option value="Roofing">Roofing</option>
                                <option value="Stone Patio">Stone Patio</option>
                                <option value="Block Foundation">Block Foundation</option>
                                </select>
                            </div>
                            <div className={Style.ContainerAdditionalInfo}>
                                <label htmlFor="additionalInfo">Additional Info:</label>
                                <textarea
                                className={Style.FixedTexarea}
                                id="additionalInfo"
                                name="additionalInfo"
                                value={additionalInfo}
                                onChange={(event) => setAdditionalInfo(event.target.value)}
                                rows={10}
                                cols={60}
                                ></textarea>
                        </div>
                    </div>
                    </div>
                    <div className={Style.VerticalBrk}></div>
                    <div className={Style.ProjectContainer}>
                        <h2>Project Details</h2>
                        <div className={Style.ProjectDetails}>
                            <div className={Style.ContainerAddress}>
                                <label htmlFor="address">Address:</label>
                                <input 
                                type="text" 
                                id="address" 
                                name="address"
                                value={address} 
                                onChange={(event)=> setAddress(event.target.value)} 
                                required />
                            </div>
                            <div className={Style.ContainerCity}>
                                <label htmlFor="city">City:</label>
                                <input 
                                type="text" 
                                id="city" 
                                name="city"
                                value={city}
                                onChange={(event)=>setCity(event.target.value)} 
                                required />
                            </div>
                            <div className={Style.ContainerState}>
                            <label htmlFor="state">State:</label>
                        <select 
                        id="state" 
                        name="state"
                        value={state}
                        onChange={(event)=> setState(event.target.value)} 
                        required>
                            <option value="">--Please select a state--</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option> 
                    </select>
                            </div>
                            <div className={Style.ContainerZipCode}>
                            <label htmlFor="zipcode">Zipcode:</label>
                            <input 
                            type="text" 
                            id="zipcode" 
                            name="zipcode"
                            value={zipcode} 
                            onChange={(event)=>setZipcode(event.target.value)}
                            required />
                            </div>
                        </div>
                        <div className={Style.PhotoContainer}>
                            <h2>Include Some Photos</h2>
                            <div className={Style.PhotoGrid}>
                                <div className={Style.PhotoBox}>
                                    {photos[0] ? (
                                    <div className={Style.PhotoPreview}>
                                        <img src={photos[0]} alt="Input 1" />
                                        <div className={Style.Overlay} onClick={() => handlePhotoDelete(0)}>
                                        <span className={Style.DeleteButton}>X</span>
                                        </div>
                                    </div>
                                    ) : (
                                    <>
                                        <label htmlFor="photo0">Press To Enter Photo 1</label>
                                        <input
                                        type="file"
                                        id="photo0"
                                        name="photos"
                                        accept="image/*"
                                        onChange={(event) => handlePhotoUpload(event, 0)}
                                        />
                                    </>
                                    )}
                                </div>

                                <div className={Style.PhotoBox}>
                                    {photos[1] ? (
                                    <div className={Style.PhotoPreview}>
                                        <img src={photos[1]} alt="Input 2" />
                                        <div className={Style.Overlay} onClick={() => handlePhotoDelete(1)}>
                                        <span className={Style.DeleteButton}>X</span>
                                        </div>
                                    </div>
                                    ) : (
                                    <>
                                        <label htmlFor="photo1">Press To Enter Photo 2</label>
                                        <input
                                        type="file"
                                        id="photo1"
                                        name="photos"
                                        accept="image/*"
                                        onChange={(event) => handlePhotoUpload(event, 1)}
                                        />
                                    </>
                                    )}
                                </div>

                                <div className={Style.PhotoBox}>
                                    {photos[2] ? (
                                    <div className={Style.PhotoPreview}>
                                        <img src={photos[2]} alt="Input 3" />
                                        <div className={Style.Overlay} onClick={() => handlePhotoDelete(2)}>
                                        <span className={Style.DeleteButton}>X</span>
                                        </div>
                                    </div>
                                    ) : (
                                    <>
                                        <label htmlFor="photo2">Press To Enter Photo 3</label>
                                        <input
                                        type="file"
                                        id="photo2"
                                        name="photos"
                                        accept="image/*"
                                        onChange={(event) => handlePhotoUpload(event, 2)}
                                        />
                                    </>
                                    )}
                                </div>

                                <div className={Style.PhotoBox}>
                                    {photos[3] ? (
                                    <div className={Style.PhotoPreview}>
                                        <img src={photos[3]} alt="Input 4" />
                                        <div className={Style.Overlay} onClick={() => handlePhotoDelete(3)}>
                                        <span className={Style.DeleteButton}>X</span>
                                        </div>
                                    </div>
                                    ) : (
                                    <>
                                        <label htmlFor="photo3">Press To Enter Photo 4</label>
                                        <input
                                        type="file"
                                        id="photo3"
                                        name="photos"
                                        accept="image/*"
                                        onChange={(event) => handlePhotoUpload(event, 3)}
                                        />
                                    </>
                                    )}
                                </div>
                            </div>
                       </div>
                    </div>
                    <div className={Style.SubmitForm}>
                        {isSubmitted ? (
                            <div className={Style.SubmitButton}>Message Sent!</div>
                        ) : (
                            <button className={`${Style.SubmitButton} ${isLoading ? Style.Loading : ''}`} type="submit" disabled={isLoading}>
                            {isLoading ? <div className={Style.Loader}></div> : 'Submit'}
                            </button>
                        )}
                 </div>

                </form>
            </div>

        </div>
    );
}

export default Contact;