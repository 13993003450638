import { Link } from 'react-router-dom';
import Style from '../styles/404.module.css';
function NotFound() {
  return (
    <div className={Style.notFound}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
      <Link to="/">
        <button className={Style.backBtn}>Back to Home</button>
      </Link>
    </div>
  );
}

export default NotFound;