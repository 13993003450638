import React,{useEffect} from "react";
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Style from '../styles/Services.module.css';
import service1 from '../imgs/stonePatioServices/service1.jpg';
import service2 from '../imgs/stonePatioServices/service2.jpg';
import service3 from '../imgs/stonePatioServices/service3.jpg';

import before1 from '../imgs/stonePatioServices/before1.jpg';
import after1 from '../imgs/stonePatioServices/after1.jpg';
import before2 from '../imgs/stonePatioServices/before2.jpg';
import after2 from '../imgs/stonePatioServices/after2.jpg';
import before3 from '../imgs/stonePatioServices/before3.jpg';
import after3 from '../imgs/stonePatioServices/after3.jpg';
import ImageBlock from "../components/ImageBlock";

const StonePatio = ()=>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <Helmet>
                <title>Stone Patio Construction - Dino Constructions</title>
                <meta name="description" content="Transform your backyard with a beautiful stone patio constructed by Dino Constructions. Our expert team can create the perfect outdoor living space for you and your family." />
                <meta property="og:title" content="Stone Patio Construction - Dino Constructions" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dinoconstructions.com/StonePatio" />
                <link rel="canonical" href="https://dinoconstructions.com/StonePatio"/>
                <meta property="og:description" content="Transform your backyard with a beautiful stone patio constructed by Dino Constructions. Our expert team can create the perfect outdoor living space for you and your family." />
            </Helmet>
            <div className={`${Style.Content1} ${Style.banner5}`}>
                <div className={Style.Content1A}>
                    <h1>Stone Patio Services</h1>
                    <Link to='/Gallery'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#AB9262"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>View Our Work</button></Link>
                    <Link to='/ContactUs'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#AB9262"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>
                   Request A Quote</button></Link>
                </div>
            </div>
            <div className={Style.Content2} style={{backgroundColor:"#AB9262"}} >
                <div className={Style.Content2Intro}>
                    <p>Transform your outdoor space with a stunning stone patio from our experts. We offer a variety of high-quality stones to choose from and use only the best techniques to ensure your patio is durable, low-maintenance, and perfectly designed to fit your needs and style. Let us help you create an inviting and beautiful outdoor area for relaxation and entertainment.</p>
                </div>
            </div>
            <div className={Style.Content3}>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service1} alt="Stone Patio"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#AB9262'}}>Experience the Benefits of a Stone Patio</h3>
                            <p>A stone patio is not only aesthetically pleasing, but it also offers a range of benefits for homeowners. Our expert craftsmen can design and install a beautiful stone patio that will enhance the overall look of your outdoor space while providing a durable and long-lasting surface. Stone patios are easy to maintain and can withstand harsh weather conditions, making them a practical choice for any home. Additionally, a stone patio adds value to your property and can create a comfortable and relaxing outdoor living space for you and your family to enjoy for years to come.</p>
                        </div>  
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service2} alt="Stone Patio"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#AB9262'}}>Types of Stone for Your Patio:</h3>
                            <p>Transform your backyard into an outdoor oasis with a stunning stone patio. With a variety of materials to choose from, you can customize your patio to fit your style and needs. Some popular options include:
                    <br></br>-   <strong>Flagstone:</strong> A natural and versatile option that comes in a range of colors and textures.
                    <br></br>-   <strong>Slate:</strong> Known for its unique, earthy appearance and durability.
                    <br></br>-   <strong>Bluestone:</strong> A type of sandstone that is incredibly dense and durable, making it ideal for high-traffic areas.
                    <br></br>-   <strong>Limestone:</strong> A durable and affordable option that comes in a range of colors.
                    <br></br>-   <strong>Travertine:</strong> A type of limestone that is known for its distinctive, textured surface and warm colors.
                    <br></br><br></br>No matter which type of stone you choose, a stone patio can add value to your home and create a beautiful outdoor space for relaxing and entertaining.</p>
                        </div> 
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service3} alt="Stone Patio"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#AB9262'}}>Maintaining Your Stone Patio</h3>
                            <p> A stone patio is a beautiful and durable addition to any outdoor space, but it's important to properly maintain it to ensure its longevity and continued beauty. Here are some tips and recommendations for maintaining your stone patio:

Regularly sweep or blow off debris to prevent scratches and buildup of dirt and grime.
Use a mild soap and water solution or a stone-specific cleaner to clean your patio periodically, especially after any spills or stains.
Avoid using abrasive cleaners or tools that can scratch or damage the surface of the stones.
Consider sealing your stone patio to protect against stains and other damage. Consult with a professional to determine the best sealing product and frequency for your specific type of stone.
Address any cracks or damage promptly to prevent further deterioration and maintain the structural integrity of your patio.
</p>
                        </div>  
                    </div>  
                </div>
            </div>
            <div className={Style.Content4} style={{backgroundColor:"#AB9262"}} >
                <div className={Style.Content4Intro}>
                    <h2>Before / After</h2>
                    <p>Using our slider tool, you can witness the transformative power of our Stone Patio services and see the remarkable difference we can make to your property. Whether you're looking to upgrade your outdoor living area or enhance the curb appeal of your home, our team of experts can deliver exceptional results that exceed your expectations. </p>
                </div>
                <div className={Style.beforeAfterGrid}>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before1} afterImage={after1} />
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before2} afterImage={after2}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before3} afterImage={after3}/>
                </div>
                </div>
            </div>
        </div>
    );
}

export default StonePatio;