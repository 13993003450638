import Navbar from './components/Navbar';
import HomePage from './pages/home';
import Concrete from './pages/serviceConcrete';
import Paint from './pages/servicePaint';
import Porches from './pages/servicePorches';
import Roofing from './pages/serviceRoofing';
import StonePatio from './pages/serviceStonePatio';
import BlockFoundation from './pages/serviceBlock';
import Gallery from './pages/gallery';
import About from './pages/about';
import Contact from './pages/contact';
import Footer from './components/Footer';
import NotFound from './pages/404';
import { Route,Routes } from 'react-router-dom';

import './App.css';

function App() {
  return (
    <div className="App">
       <Navbar/>
      <Routes basename='/'>
        <Route path='/' element={<HomePage/>}></Route>
        <Route path='/Concrete' element={<Concrete/>}></Route>
        <Route path='/Paint' element={<Paint/>}></Route>
        <Route path='/Porches-Decks-Fences' element={<Porches/>}></Route>
        <Route path='/Roofing' element={<Roofing/>}></Route>
        <Route path='/StonePatio' element={<StonePatio/>}></Route>
        <Route path='/BlockFoundation' element={<BlockFoundation/>}></Route>
        <Route path='/Gallery' element={<Gallery/>}></Route>
        <Route path='/AboutUs' element={<About/>}></Route>
        <Route path='/ContactUs' element={<Contact/>}></Route>
        <Route path='*' element={<NotFound/>}></Route>
      </Routes>
     <Footer/>
    </div>
  );
}

export default App;
