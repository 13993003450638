import React, {useEffect}from "react";
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Style from '../styles/Services.module.css';
import service1 from '../imgs/paintServices/exterioService.jpg';
import service2 from '../imgs/paintServices/interiorServices.jpg';
import service3 from '../imgs/paintServices/servicePaint.jpg';

import before1 from '../imgs/paintServices/before1.png';
import after1 from '../imgs/paintServices/after1.jpg';
import before2 from '../imgs/paintServices/before2.jpg';
import after2 from '../imgs/paintServices/after2.jpg';
import before3 from '../imgs/paintServices/before3.png';
import after3 from '../imgs/paintServices/after3.jpg';
import before4 from '../imgs/paintServices/before4.jpg';
import after4 from '../imgs/paintServices/after4.jpg';
import ImageBlock from "../components/ImageBlock";

const ServicePaint = ()=>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <Helmet>
                <title>Paint Services - Dino Constructions</title>
                <meta name="description" content="Our paint service includes a wide range of colors and styles to give your home or business the perfect look." />
                <meta property="og:title" content="Paint Services - Dino Constructions" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dinoconstructions.com/Paint" />
                <link rel="canonical" href="https://dinoconstructions.com/Paint"/>
                <meta property="og:description" content="Our paint service includes a wide range of colors and styles to give your home or business the perfect look." />
                </Helmet>
            <div className={`${Style.Content1} ${Style.banner2}`}>
                <div className={Style.Content1A}>
                    <h1>Paint Services</h1>
                    <Link to='/Gallery'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#1E98DA"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>View Our Work</button></Link>
                    <Link to='/ContactUs'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#1E98DA"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>
                   Request A Quote</button></Link>              
                </div>
            </div>
            <div className={Style.Content2} style={{backgroundColor:"#1E98DA"}} >
                <div className={Style.Content2Intro}>
                    <p>Our team of skilled painters has years of experience working with different paint mediums to create beautiful finishes for your walls, ceilings, and exteriors. We use only
                    high-quality paints and materials to ensure a flawless and durable finish that will stand the test of time. Let us help you transform your space with our expertise in painting.</p>
                </div>
            </div>
            <div className={Style.Content3}>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service1} alt="Exterior Home Paint"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#1E98DA'}}>Exterior Painting Services for Your Home </h3>
                            <p>Our expert exterior painting services are available for both new construction and existing homes. Whether you have a brand-new home that needs a fresh coat of paint or simply 
                                want to update the color of your existing home, our team can help. We use high-quality paints and materials to ensure a long-lasting and durable finish that enhances the beauty 
                                and value of your home.We understand that exterior painting can be a significant investment for homeowners. That's why we are committed to providing affordable and transparent 
                                pricing, exceptional customer service, and outstanding results.  </p>
                        </div>  
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service2} alt="Interior Wall Paint"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#1E98DA'}}>Bring Your Walls to Life with Our Interior Painting Services</h3>
                            <p>Are you looking to transform the interior of your home with a fresh coat of paint? Look no further than our professional indoor painting services! Our experienced painters use
                                high-quality paints and techniques to ensure a flawless finish on every project, no matter how big or small. We take pride in our attention to detail and will work with you to
                                choose the perfect colors and finishes to complement your style and enhance the ambiance of your home. From walls and ceilings to trim and doors, we've got you covered. Don't 
                                settle for a DIY paint job that leaves you with streaks, drips, and uneven coverage - trust our experts to deliver beautiful, long-lasting results that will make your home look
                                and feel like new. </p>
                        </div> 
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service3} alt="Concrete Stairs"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#1E98DA'}}>Unleash the Beauty of Your Deck</h3>
                            <p>Your deck is more than just a space to relax or entertain - it's an extension of your home and a reflection of your personal style. That's why we're dedicated to providing you with 
                                the highest quality deck painting services to bring your vision to life. Our team of skilled professionals will work with you every step of the way to ensure your satisfaction, from
                                 selecting the perfect color scheme to using the latest techniques for maximum durability. Plus, with our competitive pricing and flexible scheduling, there's never been a better time 
                                 to elevate your outdoor living experience. Contact us today to start your transformation!</p>
                        </div>  
                    </div>  
                </div>
            </div>
            <div className={Style.Content4} style={{backgroundColor:"#1E98DA"}} >
                <div className={Style.Content4Intro}>
                    <h2>Before / After</h2>
                    <p>Using our slider tool, you can witness the transformative power of our Paint services and see the remarkable difference we can make to your property. Whether you're looking to upgrade your outdoor living area or enhance the curb appeal of your home, our team of experts can deliver exceptional results that exceed your expectations.</p>
                </div>
                <div className={Style.beforeAfterGrid}>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before1} afterImage={after1} />
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before2} afterImage={after2}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before3} afterImage={after3}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before4} afterImage={after4}/>
                </div>
                </div>
            </div>
        </div>
    );
}

export default ServicePaint;