import React,{useEffect} from "react";
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Style from '../styles/Services.module.css';
import service1 from '../imgs/blockServices/wallService.jpg';
import service2 from '../imgs/blockServices/foundationServices.jpg';
import service3 from '../imgs/blockServices/retainingWall.jpg';

import after1 from '../imgs/blockServices/after1.jpg';
import before1 from '../imgs/blockServices/before1.jpg';
import after2 from '../imgs/blockServices/after2.jpg';
import before2 from '../imgs/blockServices/before2.jpg';
import ImageBlock from "../components/ImageBlock";

const Roofing = ()=>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
             <Helmet>
                <title>Block Foundation - Dino Constructions</title>
                <meta name="description" content="We provide professional block foundation services to ensure the stability of your building's structure. Contact us today to schedule a consultation." />
                <meta property="og:title" content="Block Foundation - Dino Constructions" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dinoconstructions.com/BlockFoundation"/>
                <link rel="canonical" href="https://dinoconstructions.com/BlockFoundation"/>
                <meta property="og:description" content="We provide professional block foundation services to ensure the stability of your building's structure. Contact us today to schedule a consultation." />
            </Helmet>
            <div className={`${Style.Content1} ${Style.banner6}`}>
                <div className={Style.Content1A}>
                    <h1>Block Foundation Services</h1>
                    <Link to='/Gallery'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>View Our Work</button></Link>
                    <Link to='/ContactUs'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>
                   Request A Quote</button></Link>
                </div>
            </div>
            <div className={Style.Content2} style={{backgroundColor:"#504D48"}} >
                <div className={Style.Content2Intro}>
                    <p>Our block foundation services are designed to provide your home with a strong and stable foundation that can withstand the test of time. Whether you need a new foundation for a new construction project or repairs to your existing foundation, our team of experts has the skills and experience to get the job done right.</p>
                </div>
            </div>
            <div className={Style.Content3}>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service1} alt="Block Wall"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#504D48'}}>Strong and Stunning</h3>
                            <p>Transform your outdoor space with our professional block wall services. We specialize in building durable and beautiful block walls
                                that add privacy, security, and value to your property. Our team of experts uses the best materials and techniques to create a 
                                custom-designed block wall that perfectly fits your unique needs and style. Whether you need a retaining wall, garden wall, or a 
                                decorative block wall, we can bring your vision to life. Trust us to enhance your outdoor living space and create a lasting 
                                impression with our high-quality block wall services.</p>
                        </div>  
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service2} alt="Block Foundation"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#504D48'}}>Build a Solid Foundation for Your Home </h3>
                            <p>The foundation of your home is one of the most critical components of its structure. Our block foundation services offer a reliable and long-lasting
                                 solution to ensure the stability and security of your home. We use high-quality materials and proven techniques to construct block foundations that 
                                 can withstand the toughest weather conditions and provide the necessary support for your home. Our team of skilled professionals will work with you
                                 to assess your needs and provide a customized solution that meets your requirements and budget.</p>
                        </div> 
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service3} alt="Retaining Wall"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3 style={{color:'#504D48'}}>Enhance Your Landscape with Our Expert Retaining Wall Services</h3>
                            <p> A retaining wall can transform the look and function of your outdoor space. Our expert retaining wall services provide a durable and attractive solution to hold back soil, prevent erosion, and create defined garden beds. We use high-quality materials and skilled techniques to design and construct retaining walls that complement your landscape's natural beauty and architecture. Our team of experienced professionals will work with you to understand your needs and recommend the best options for your retaining wall.</p>
                        </div>  
                    </div>  
                </div>
            </div>
            <div className={Style.Content4} style={{backgroundColor:"#504D48"}} >
                <div className={Style.Content4Intro}>
                    <h2>Before / After</h2>
                    <p>Using our slider tool, you can witness the transformative power of our Block Foundation services and see the remarkable difference we can make to your property. Whether you're looking to upgrade your outdoor living area or enhance the curb appeal of your home, our team of experts can deliver exceptional results that exceed your expectations. </p>
                </div>
                <div className={Style.beforeAfterGrid}>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before1} afterImage={after1} />
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before2} afterImage={after2}/>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Roofing;