import React, {useState} from "react";
import Style from '../styles/Services.module.css';

function ImageBlock({ beforeImage, afterImage, name }) {
    const [sliderValue, setSliderValue] = useState(0);
  const [sliderClicked, setSliderClicked] = useState(false);
  
    const handleSliderChange = (event) => {
      setSliderValue(event.target.value);
      setSliderClicked(true);
    };
    const handleSliderRelease = () => {
      setSliderClicked(false);
  }
  
    return (
      <div className={Style.Content4Img}>
        <div className={Style.ImgContainer}>
          <div className={Style.ImgBlock}>
            <div className={Style.BeforeAfterContainer}>
              <img
                className={Style.BeforeImage}
                src={beforeImage}
                alt="Before"
                style={{ opacity: (100 - sliderValue) / 100 }}
              />
              <img
                className={Style.AfterImage}
                src={afterImage}
                alt="After"
                style={{ opacity: sliderValue / 100 }}
              />
              {!sliderClicked && (
            <div className={`${Style.Overlay}`}>
            </div>
          )}
              <input
                className={Style.Slider}
                type="range"
                min="0"
                max="100"
                step="1"
                value={sliderValue}
                onChange={handleSliderChange}
                onMouseUp={handleSliderRelease}
              />
            </div>
          </div>
        </div>
        <div className={Style.ImgName}>
          <h3>{name}</h3>
        </div>
    </div>
    );
  }
  export default ImageBlock;