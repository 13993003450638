import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Styles from '../styles/Navbar.module.css';
import logo from '../imgs/logoImg.png';


const Navbar = () => {
  const [navHeight, setNavHeight] = useState(0);

  useEffect(() => {
    setNavHeight(document.getElementById('my-nav').offsetHeight);
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > navHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navHeight]);
     const [isExpanded, setIsExpanded] = useState(false);

const handleToggleClick = () => {
  setIsExpanded(!isExpanded);
  
};
const handleLinkClick = () => {
  setIsExpanded(false);
};




return  (
  <nav id="my-nav" className={`${Styles.navbar} ${isExpanded && Styles["navbar-expanded"]} ${isScrolled && Styles["navbar-scrolled"]}`}>
    <div className={Styles.logoImg}>
      <Link to='/'><img src={logo} alt='Company Logo'/></Link>
    </div>
    <ul className={`${Styles.navbarItems} ${isExpanded && Styles["navbarItems-expanded"]}`}>
      <li> Services
        <ul className={Styles.servicesOptions}>
          <li><Link to='/Concrete' onClick={handleLinkClick}>Concrete</Link></li>
          <li><Link to='/Paint' onClick={handleLinkClick}>Paint</Link></li>
          <li><Link to='/Porches-Decks-Fences' onClick={handleLinkClick}>Decks/Fences</Link></li>
          <li><Link to='/Roofing' onClick={handleLinkClick}>Roofing</Link></li>
          <li><Link to='/StonePatio' onClick={handleLinkClick}>Stone Patio</Link></li>
          <li><Link to='/BlockFoundation' onClick={handleLinkClick}>Block Foundation</Link></li>
        </ul>
      </li>
      <li><Link to='/Gallery' onClick={handleLinkClick}>Gallery</Link></li>
      <li><Link to='/AboutUs' onClick={handleLinkClick}>About Us</Link></li>
      <li><Link to='/ContactUs' onClick={handleLinkClick}>Contact</Link></li>
    </ul>
    <div className={Styles.hamburger} onClick={handleToggleClick}>
      <div className={Styles.line}></div>
      <div className={Styles.line}></div>
      <div className={Styles.line}></div>
    </div>
  </nav>
);
};

export default Navbar;
