import React,{useEffect} from "react";
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Style from '../styles/Services.module.css';
import service1 from '../imgs/concreteService/drivewayService.jpg';
import service2 from '../imgs/concreteService/sidewalkService.jpg';
import service3 from '../imgs/concreteService/anyServices.jpg';
import before1 from '../imgs/concreteService/before1.jpg';
import after1 from '../imgs/concreteService/after1.jpg';
import before2 from '../imgs/concreteService/before2.jpg';
import after2 from '../imgs/concreteService/after2.jpg';
import before3 from '../imgs/concreteService/before3.jpg';
import after3 from '../imgs/concreteService/after3.jpg';
import before4 from '../imgs/concreteService/berfor4.jpg';
import after4 from '../imgs/concreteService/after4.jpg';
import ImageBlock from "../components/ImageBlock";

const ServiceConcrete = ()=>{
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div> 
              <Helmet>
                    <title>Concrete Services - Dino Constructions</title>
                    <meta name="description" content="Learn more about our expert concrete services, including driveways, sidewalks, and patios." />
                    <meta property="og:title" content="Concrete Services - Dino Constructions" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://dinoconstructions.com/Concrete" />
                    <link rel="canonical" href="https://dinoconstructions.com/Concrete"/>
                    <meta property="og:description" content="Learn more about our expert concrete services, including driveways, sidewalks, and patios." />
                </Helmet>
            <div className={`${Style.Content1} ${Style.banner1}`}>
                <div className={Style.Content1A}>
                    <h1>Concrete Services</h1>
                    <Link to='/Gallery'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#E08181"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>View Our Work</button></Link>
                    <Link to='/ContactUs'><button style={{ border: "4px solid white" }} onMouseOver={(e) => { e.target.style.color = "#E08181"; e.target.style.backgroundColor = "#323232";}} onMouseOut={(e) => { e.target.style.color = "white"; e.target.style.backgroundColor='transparent';}}>
                   Request A Quote</button></Link>
                </div>
            </div>
            <div className={Style.Content2} style={{backgroundColor:"grey"}} >
                <div className={Style.Content2Intro}>
                    <p>Concrete is an essential building material that offers excellent durability and strength to any construction project. Our team of expert craftsmen has years of experience
                    in working with concrete to build decks that are not only durable but also low-maintenance and beautifully designed.</p>
                </div>
            </div>
            <div className={Style.Content3}>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service1} alt="Concrete Driveways"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3>Beautiful and Durable Driveway </h3>
                            <p>At our concrete company, we offer a range of services to meet our clients' needs. Our driveway installation service is one of our most popular offerings. Our team has extensive 
                                experience designing and installing durable, beautiful driveways that can withstand heavy traffic, harsh weather, and regular wear and tear.
                                We offer a range of finishes, textures, and colors to choose from, so you can get the perfect look for your home or business. We use advanced techniques and equipment to ensure that your driveway is both functional and aesthetically pleasing.
                                We are committed to ensuring your complete satisfaction
                                and work closely with you throughout the entire process to ensure that your project is completed on time, within budget, and to your exact specifications. Choose our driveway installation
                                service for a beautiful, long-lasting addition to your property.</p>
                        </div>  
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service2} alt="Concrete SideWalks"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3>Improve Your Property with Quality Concrete Sidewalks </h3>
                            <p>At our concrete service, we offer high-quality and durable concrete sidewalks that are designed to withstand heavy foot traffic, extreme weather conditions, and regular wear and
                                 tear. Our skilled craftsmen use only the finest quality materials and techniques to ensure that your sidewalks are not only strong and sturdy but also low-maintenance and beautifully
                                  designed. Whether you need a new sidewalk installed or your existing one repaired or replaced, we are here to provide you with the best possible service and results that exceed your 
                                  expectations. Contact us today to learn more about our sidewalk services and how we can help you enhance the functionality and curb appeal of your property.</p>
                        </div>  
                    </div>  
                </div>
                <div className={Style.Sections}>
                    <div className={Style.PartA}>
                        <div className={Style.PartAImg}>
                            <img src={service3} alt="Concrete Stairs"></img>
                        </div>
                    </div>
                    <div className={Style.PartB}>
                        <div className={Style.PartBDesc}>
                            <h3>Sturdy and Stylish Concrete Stairs </h3>
                            <p>Our team of skilled craftsmen can design and construct beautiful and sturdy concrete stairs for your 
                                property. With our attention to detail and use of high-quality materials and techniques, we can ensure that your 
                                concrete stairs are not only visually appealing, but also safe and durable for many years to come. Whether you need 
                                new stairs installed or require repairs or replacements, we are here to provide you with excellent service and results. 
                                Contact us today to learn more about our expert concrete services.</p>
                        </div>  
                    </div>  
                </div>
            </div>
            <div className={Style.Content4}>
                <div className={Style.Content4Intro}>
                    <h2>Before / After</h2>
                    <p>Transform your outdoor space with our concrete repair services. Using our slider tool, you can compare before and after pictures of our impressive concrete projects and witness the significant change our services can bring to your property. Don't settle for cracked and worn concrete - let us give your outdoor living area a stunning makeover.</p>
                </div>
                <div className={Style.beforeAfterGrid}>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before1} afterImage={after1} />
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before2} afterImage={after2}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before3} afterImage={after3}/>
                </div>
                <div className={Style.beforeAfter}>
                    <ImageBlock beforeImage={before4} afterImage={after4}/>
                </div>
                </div>
            </div>
        </div>
    );
    
}

export default ServiceConcrete;